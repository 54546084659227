<template>
  <div class="interview">
    <!-- 标题 & 说明 -->
    <div class="title">
      <h2>技术评估</h2>
      <p>平台筛选优质面试官，根据您提交的信息对您进行模拟面试，最终评估出技术水平、薪资范围，给出需要补充的技术点等。面试通过后，平台将为您发放<em>「人才勋章」</em>，为了表达对技术人员的尊重和支持，站长将亲自制作<em>「PIPA技术资质证书」</em>并寄送至您的接收地址。</p>
    </div>
    <!-- 步骤 -->
    <el-steps :active="0" align-center>
      <el-step title="预约技术评估面试" description="填写面试资料"></el-step>
      <el-step title="等待面试" description="填写面试资料"></el-step>
      <el-step title="远程面试" description="面试时间约15至60分钟"></el-step>
      <el-step title="结果通知" description="系统通知面试结果和建议"></el-step>
    </el-steps>
    <div class="main">
      <!-- 资质证书/预约时间 -->
      <div class="appoint-time-wrap">
        <div class="block">
          <h3><i></i>预约时间</h3>
          <p class="appoint-time">2022年7月15日 20:00（明晚8点）</p>
        </div>
        <div class="block">
          <h3><i></i>选择预约日期</h3>
          <el-calendar :range="appointDate"></el-calendar>
        </div>
        <div class="block">
          <h3><i></i>选择面试场次</h3>
          <el-radio-group class="primary-radio-group">
            <el-radio-button label="上午10点"/>
            <el-radio-button label="上午11点"/>
            <el-radio-button label="下午3点"/>
            <el-radio-button label="下午4点"/>
            <el-radio-button label="下午5点"/>
            <el-radio-button label="晚上8点"/>
            <el-radio-button label="晚上9点"/>
          </el-radio-group>
        </div>
      </div>
      <!-- 资料填写 -->
      <div class="form">
        <h3><i></i><span>填写面试资料</span></h3>
        <el-form label-width="120px">
          <el-form-item label="真实姓名" required>
            <el-input placeholder="请输入真实姓名"/>
          </el-form-item>
          <el-form-item label="手机号码" required>
            <el-input placeholder="请输入手机号码"/>
          </el-form-item>
          <el-form-item label="面试岗位" required>
            <el-select>
              <el-option value="front" label="前端工程师"/>
              <el-option value="endpoint" label="后端工程师"/>
            </el-select>
          </el-form-item>
          <el-form-item label="面试岗位等级" required>
            <el-radio-group class="primary-radio-group">
              <el-radio-button label="10">初级</el-radio-button>
              <el-radio-button label="20">中级</el-radio-button>
              <el-radio-button label="50">高级</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="当前工作年限" required>
            <el-select>
              <el-option value="0" label="未参与过工作"/>
              <el-option value="1" label="不满一年"/>
              <el-option value="3" label="不满三年"/>
              <el-option value="5" label="不满五年"/>
              <el-option value="10" label="不满十年"/>
              <el-option value="15" label="不满十五年"/>
              <el-option value="20" label="不满二十年"/>
              <el-option value="30" label="不满三十年"/>
            </el-select>
          </el-form-item>
          <el-form-item label="期望薪资">
            <el-input placeholder="请输入期望的税前月薪"/>
          </el-form-item>
          <el-form-item label="简历附件">
            <el-upload
              drag
              action="https://jsonplaceholder.typicode.com/posts/"
              multiple
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            </el-upload>
          </el-form-item>
          <el-form-item label="证书接收地址" required>
            <el-input type="textarea" :rows="3" placeholder="请输入证书接收地址" resize="none"/>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" :rows="3" placeholder="有什么特别交代的，请在此说明！" resize="none"/>
          </el-form-item>
          <el-form-item label="原价">
            <span class="price">¥200</span>
          </el-form-item>
          <el-form-item label="应支付">
            <span class="price">¥0（首次免费）</span>
          </el-form-item>
        </el-form>
        <div class="opera">
          <Button type="solid" theme="function" size="big" @click="cut">
            立即预约
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      appointDate: [this.$moment().format('YYYY-MM-DD'), this.$moment().add(1, 'months').format('YYYY-MM-DD')]
    }
  },
  methods: {
    cut () {}
  },
  mounted () {
    console.log(this.$moment().format('YYYY-MM-DD'))
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/variables";
.interview{
  margin-top: 20px;
  padding: 40px 70px;
  box-sizing: border-box;
  background-color: $background-color;
  // 标题
  .title{
    margin-bottom: 50px;
    h2 {
      text-align: center;
      margin: 0 0 30px 0;
      font-size: 30px;
      font-weight: normal;
    }
    p {
      margin: 0;
      color: $font-color-third;
      font-size: 16px;
      line-height: 26px;
      font-weight: lighter;
      letter-spacing: 1px;
      text-indent: 34px;
      em {
        font-style: normal;
        font-weight: bold;
      }
    }
  }
  // 步骤
  .el-steps {
    margin-bottom: 50px;
    /deep/ .el-step__title {
      font-weight: normal !important;
    }
  }
  // 主体
  .main{
    display: flex;
    // 预约时间
    .appoint-time-wrap {
      flex-grow: 1;
      overflow: hidden;
      margin: 0 20px 0 0;
      padding: 20px 0;
      .block {
        margin-bottom: 30px;
        &:last-of-type {
          border-bottom: 0;
        }
        // 面试通过奖励
        & > ul {
          list-style: circle;
          li {
            padding: 5px 0;
          }
        }
        // 预约时间
        .appoint-time {
          font-size: 20px;
          font-weight: bold;
        }
        // 日期选择
        .el-calendar {
          // 操作栏
          /deep/ .el-calendar__header {
            padding: 0;
            border-bottom: 0;
          }
          // 日历天数列表
          /deep/ .el-calendar__body {
            padding: 0 0 12px 0;
            .is-selected {
              .el-calendar-day {
                background-color: $primary-color;
                color: #fff;
                font-size: 16px;
              }
            }
            .el-calendar-day {
              height: 45px;
              display: flex;
              align-items: center;
              justify-content: center;
              &:hover {
                background-color: $primary-color-hover;
                color: $primary-color;
              }
            }
          }
        }
        // 场次选择
        .el-radio-group {
          flex-wrap: wrap;
          justify-content: space-between;
          .el-radio-button {
            margin-bottom: 15px;
            width: 30%;
            margin-right: 0;
            /deep/ .el-radio-button__inner {
              display: block;
            }
          }
        }
      }
    }
    // 资料填写
    .form {
      width: 600px;
      flex-shrink: 0;
      box-shadow: $background-shadow;
      border-radius: 20px;
      padding: 20px;
      box-sizing: border-box;
      background-color: #fff;
      h3 {
        margin-bottom: 30px;
      }
      .el-form {
        /deep/ .el-upload {
          display: block;
          .el-upload-dragger {
            width: 100%;
          }
        }
        /deep/ .el-select {
          width: 100%;
        }
        .price {
          font-size: 20px;
          color: $primary-color;
          margin-left: 10px;
        }
      }
      // 操作
      .opera {
        margin-top: 20px;
        padding-left: 120px;
        color: $font-color-third;
        .el-button {
          padding: 16px 60px;
          font-size: 16px;
          border-radius: 40px;
        }
      }
    }
  }
  h3 {
    margin: 0 0 20px 0;
    font-size: 16px;
    font-weight: normal;
    position: relative;
    padding-left: 15px;
    i {
      display: inline-block;
      width: 5px;
      height: 19px;
      background: $primary-color;
      position: absolute;
      top: 2px;
      left: 0;
      border-radius: 3px;
    }
  }
}
</style>
